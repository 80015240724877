$bg-charcoal: #262121;
$bg-darkblue: #0D242E;
$bg-darkgreen: #262121;
$bg-darkpurple: #2E1C2E;
$bg-lightgrey: #3B5263;
$bg-blue1: #28963C;
$bg-blue2: #596B7A;
$bg-blue3: #808F99;
$bg-blue4: #B0BFC9;
$bg-green1: #33540D;
$bg-green2: #789440;
$bg-green3: #8FA859;
$bg-green4: #ABC278;
$bg-orange1: #B54A33;
$bg-orange2: #D47845;
$bg-orange3: #DE9145;
$bg-orange4: #DE9145;
$header_text_blue: #012c5b;
$header_green_bg: #abc278;
$footer_green_bg: #789440;
$footer_text_charcoal: #262121;
$lightgreen: #78ad5f;
$darkgreen: #203e0b;
$medium_dark_green:#529736;