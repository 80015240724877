.environment {
    text-align: center;
    vertical-align: middle;
    color: white;
    padding: 4px 0;

    &.dev {
        background-color: blue;
    }

    &.uat {
        background-color: goldenrod;
    }
}

