.app-footer {
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100%;
    background-color: #282c34;
    min-height: 5vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;  
}