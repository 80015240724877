@import '../../../Assets/css/mixins';

.sidebar {
    min-height: 500px;
}

.sidenav---sidenav---_2tBP {
    background-color: $medium_dark_green;
    z-index: 0;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
    color: white;
}

.img {
    color: white !important;
    font-size: 1.75em;
}