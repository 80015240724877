html, body {
    height: 100%;
}
  
.App .App-Content {
  min-height: 50vh;

  .App-logo {
    height: 20vmin;
    pointer-events: none;
    padding: 10px;
  }
}

.App-logo-nav {
  max-width: 200px;
}

.gerx-hand-pointer {
  cursor: pointer
}

.container-fluid {
  height: 900px;
}