.pagination-table {
    display: flex;
    flex-direction: column;

    .paginator {
        z-index: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}