@import '../../../Assets/css/mixins';

@mixin button {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    //background-color: $bg-green3;
    color: white;
    //box-shadow: 0 0 0 1px rgb(49 132 253);
    cursor: pointer;
    border: 1px solid $bg-green1;
    margin: 0 6px;
    opacity: 1;

    &:hover {
        color: white;
        opacity: 0.8;
    }
}

@mixin green-button {
    @include button;
    background: linear-gradient(to right, $bg-green1, $bg-green2);
}

@mixin blue-button {
    @include button;
    background: linear-gradient(to right, #076aff, #4f95fd);
}

.gerx-menubar {
    display: flex;
    flex-direction: row;
    width: 100%;

    .navbar {
        width: 100%;
        background-image: linear-gradient(to right, white, $lightgreen, $darkgreen);

        .navbar-nav {
            align-items: center;

            .nav-link {
                color: #e0e0e0;
                font-family: sans-serif;
                font-size: 14px;

                &.login {
                    @include green-button;

                    font-weight: bold;
                    font-size: 12px;
                    border-radius: 4px;
                    padding: 6px 8px;
                }

                &.signup {
                    @include blue-button;

                    font-weight: bold;
                    font-size: 12px;
                    border-radius: 4px;
                    padding: 6px 8px;
                }
                
                &:active {
                    color: white;
                }

                &:hover {
                    color: white;
                }

                &:focus {
                    color: white;
                }
            }

            .btn-link {
                font-size: small;
            }
        }
    }
}